body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.congoText {
  color: #002FA7;
  font-size: 32px;
  font-weight: 500;
}

.congoSubText {
  color: #1B1B1F;
  font-size: 18px;
  font-weight: 700;
  width: 45%;
  word-wrap: break-word;
}

.congoSubText1 {
  color: #5F5E62;
  font-size: 14px;
  font-weight: 400;
  width: 60%;
  word-wrap: break-word;
}

.modalCtmContent .modal-content {
  border-radius: 15px;
}

.bg-note {
  background-color: #DBE5FF;
  border-radius: 10px;
}

.note-1 {
  color: #001854;
  font-size: 22px;
  font-weight: 600;
}

.note-2 {
  font-size: 16px;
  color: #001854;
}

.viewProfileBtn {
  border: 1px solid #0a58ca;
  border-radius: 10px;
  background-color: #DBE5FF;
  color: #001854;
  font-weight: 500;
}

@media only screen and (max-width: 600px) {
  .congoSubText {
    color: #1B1B1F;
    font-size: 18px;
    font-weight: 700;
    width: 95%;
    word-wrap: break-word;
  }
  
  .congoSubText1 {
    color: #5F5E62;
    font-size: 14px;
    font-weight: 400;
    width: 95%;
    word-wrap: break-word;
  }
}

.ratingText {
  font-size: 14px;
  font-weight: 600;
  text-align: center!important;
}

.image-stack img{
  margin-left: -20px;
}
.image-stack {
  display: flex;
    justify-content: center;
}

.image-stack img {
  position: sticky;
}

.image-stack img:nth-child(1) {
  z-index: 4;
  left: 10px;
}

.image-stack img:nth-child(2) {
  z-index: 3;
  left: 20px;
}

.image-stack img:nth-child(3) {
  z-index: 2;
  left: 40px;
}

.image-stack img:nth-child(4) {
  z-index: 1;
  left: 60px;
}


.dragData {
  width: 100px;
  height: 100px;
  background-color: #ffff;
  color: #000;
  box-shadow: 4px 4px 20px 0px #B8C4FF40;
  border-color: #E6E6E6;
  border-radius: 10px;
}

.drop_here_remove p {
  display: none;
}