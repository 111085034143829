
/* Base toast style */
.Toastify__toast {
    border-radius: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    font-family: 'Arial', sans-serif;
    background: #cce5ff;
    border: 1px solid #b8daff;
    border-left: 4px solid #004085;
    padding: 16px;
    margin: 8px;
    color: #004085; /* Default text color set to blue */
  }
  
  /* Specific styles for success, info, error, and warning toasts */
  .Toastify__toast--success,
  .Toastify__toast--info,
  .Toastify__toast--error,
  .Toastify__toast--warning {
    border-left-color: #004085 !important; /* Blue left border for all toast types */
  }
  
  .Toastify__toast--info .Toastify__close-button,
  .Toastify__toast--info .Toastify__toast-icon,
  .Toastify__toast--success .Toastify__close-button,
  .Toastify__toast--success .Toastify__toast-icon,
  .Toastify__toast--error .Toastify__close-button,
  .Toastify__toast--error .Toastify__toast-icon,
  .Toastify__toast--warning .Toastify__close-button,
  .Toastify__toast--warning .Toastify__toast-icon {
    color: #004085 !important; /* Blue color for close button and icon for all toast types */
  }
  
  /* Progress bar */
  .Toastify__progress-bar {
    background: #004085 !important; /* Blue progress bar */
    height: 4px;
    border-radius: 15px;
  }
  
  /* Close button */
  .Toastify__close-button {
    color: #004085; /* Blue color for the close button */
  }
  
  /* Toast icon */
  .Toastify__toast-icon {
    color: #004085; /* Blue color for the toast icon */
  }
  
  /* Override icon colors for success and error toasts */
.Toastify__toast--success .Toastify__toast-icon {
    color: #004085 !important; /* Blue color for success icon */
  }
  
  .Toastify__toast--error .Toastify__toast-icon {
    color: #004085 !important; /* Blue color for error icon */
  }
  
  /* Hide the default toast icons */
.Toastify__toast--success .Toastify__toast-icon,
.Toastify__toast--error .Toastify__toast-icon {
    display: none;
}

/* Add custom icon for success toast */
.Toastify__toast--success {
    background-image: url('../images/success.svg'); /* Path to your custom success icon */
    background-repeat: no-repeat;
    background-position: 20px center; /* Adjust as needed */
    padding-left: 50px; /* Adjust padding to accommodate the icon */
}

/* Add custom icon for error toast */
.Toastify__toast--error {
    background-image:  url('../images/Error.svg'); /* Path to your custom error icon */
    background-repeat: no-repeat;
    background-position: 20px center; /* Adjust as needed */
    padding-left: 50px; /* Adjust padding to accommodate the icon */
}